.cont {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;

  .video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .filter {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
  }
}

