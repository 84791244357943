$text-color: #212121;
$headerText-color: #7e7e7e;
$border-color: #90caf9;
$point-color: #2196f3;
$background-color: #e3f2fd;
$alert-color: #cd3030;

$blue-color: #45a7ea;
$orange-color: #FF9116;
$red-color: #ff7177;
$white-color: #FFFFFF;
$black-color: #000000;

$chat-admin-color: #9dd5fa;

$buttonOne-color: $blue-color;
$buttonOne-hover-color: $point-color;