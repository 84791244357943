@import "../../../../styles/temp2/temp2";

.cont {
  width: 450px;
  padding: 40px;
  background: white;
  border-radius: 14px;
  height: 870px;

  .box-form {
    p {
      margin-bottom: 5px !important;
      font-size: 0.8rem;
      text-align: right;
      color: #0768ac;
    }

    h3 {
      text-align: center;
      color: $point-color;
      font-size: 1.5rem;
    }

    h4 {
      text-align: center;
      color: $headerText-color;
      font-size: 0.8rem;
      margin-bottom: 30px;
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 5px !important;
    font-size: 0.8rem;
  }

  .box-terms {
    display: flex;

    label {
      display: block;
      flex-grow: 1;
    }

    .check-box {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-right: 10px;
    }
  }

  .box-code {
    display: flex;

    .select {
      width: 130px !important;
      margin-right: 10px;
    }
  }
}

.info {
  font-size: 0.8rem !important;
}

.copyright {
  text-align: center;
  margin: 30px 0 30px 0;
  font-size: 0.8rem;
  color: #a8a8a8
}

hr {
  margin: 24px 0;
  border-width: 0 0 thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 998px) {
  .cont {
    width: 98vw;
    min-width: 98vw;
  }
}