@import "../../../../../../styles/table";

.statusCell {
  width: 10%;
}

.dateCell {
  width: 20%;
}

.depositorCell {
  width: 20%;
}

.pointCell {
  width: 20%;
}

.depositCell {
  width: 20%;
}

.funcCell {
  width: 10%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .statusCell {
    width: 20% !important;
  }

  .depositorCell {
    width: 30% !important;
  }

  .depositCell {
    width: 35%;
  }

  .funcCell {
    width: 15%;
  }

  .hiddenCell {
    display: none !important;
  }
}