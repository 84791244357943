@import "src/styles/table";

.dateCell {
  width: 20%;
}

.pointCell {
  width: 20%;
}

.chargeCell {
  width: 20%;
}

.chargePointCell {
  width: 20%;
}

.spendCell {
  width: 20%;
}

.textRight {
  text-align: right !important;
}