.space {
  flex-grow: 1;
}

.appbar {
  background: rgba(0, 0, 0, 0.5) !important;
}

.btn-color {
  color: #eeeeee !important;
}

.nav-btm {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  filter: blur(50%);
  background: rgba(0, 0, 0, 0.5) !important;
}

.body {
  background-color: #f4f5f8;
}