@import "src/styles/table";


.cont-finder {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  input {
    width: 200px;
    margin-right: 10px;
  }

  button {
    width: 80px;
  }
}

.numberCell {
  width: 10%;
}

.activateCell {
  width: 20%;
}

.userCell {
  width: 26%;
}

.balanceCell {
  width: 20%;
}

.priceCell {
  width: 20%;
}

.funcCell {
  width: 13%;
}

.textRight {
  text-align: right;
}