@import "/src/styles/temp1/temp1";

.fullContainer {
  padding-top: 0;
}

.secondSection {
  margin-top: 15px;
}

.thirdSection {
  margin-top: 15px;
}

.dateCommCell {
  width: 20%;
}

.statusCell {
  width: 20%;
}

.methodCell {
  width: 20%;
}

.numberCommCell {
  width: 20%;
}

.sumCommCell {
  width: 20%;
}


.fourthSection {
  margin-top: 15px;
}

.sumCell {
  width: 33.33%;
}

.completeCell {
  width: 33.33%;
}

.incompleteCell {
  width: 33.33%;
}

.dateCell {
  width: 15%;
}

.badgeCell {
  width: 15%;
}

.requestCell {
  width: 15%;
}

.agentCell {
  width: 25%;
}

.valueCell {
  width: 13.3%;
}

.percentCell {
  width: 13.3%;
}

.marginCell {
  width: 13.3%;
}

.commissionCell {
  width: 13.3%;
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
  }

  .title-left {
    width: 60% !important;
  }

  .title-right {
    width: 40% !important;
  }

  .numberCommCell {
    width: 20% !important;
  }

  .sumCommCell {
    width: 20% !important;
  }

  .badgeCell {
    width: 20% !important;
  }

  .hiddenCell {
    display: none !important;
  }
}