@font-face {
  font-family: Nato Sans Bold;
  src: url("../../../../assets/fonts/nato-sans/NotoSansKR-Bold.otf");
}
#firstSection {
  width: 100%;
  height: 100vh;

  position: relative;

  .contentBox {
    max-width: 1300px;
    height: 100%;
    margin: 0 auto;
    padding: 100px 50px 140px;
    position: relative;

    h2 {
      margin-top: 140px;
      font-size: 4.706rem;
      line-height: 5.882rem;
      font-weight: 800;
      text-align: left;
      text-shadow: 2px 2px 4px black;

      color: #ffffff;
      word-break: break-word;
    }

    .contentBox-sub {
      color: #ffffff;
      text-shadow: 2px 2px 4px gray;
      margin-top: 84px;
      font-size: 1.5rem;
      line-height: 1rem;
      text-align: left;
    }
  }
}

.contentBox-indicator {
  position: absolute;
  bottom: 150px;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-50%);

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 32px;
    height: 32px;
    margin: -10px 0 0 -15px;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    transform: rotate(-45deg);
    animation: indicateIntro 1.5s infinite;
  }
}

#secondSection {
  width: 100%;
  height: 550px;
  overflow: hidden;
  background-color: #FFFFFF;

  position: relative;

  .secondSection-headBox {
    max-width: 1299px;
    margin: 150px auto 0;
    display: flex;
    justify-content: space-between;

    h2 {
      width: 50%;
      color: #000000;
      font-size: 3.4rem;
      line-height: 3.4rem;
      font-weight: 800;
    }

    p {
      width: 50%;
      font-size: 1.2rem;
    }
  }

  .secondSection-cardArea {
    width: 100%;
    max-width: 1299px;
    margin: 50px auto;

    display: flex;
    justify-content: space-between;

    .cardBox {
      width: 24%;
      margin-bottom: 50px;
      color: #000000;

      h5 {
        display: flex;
        justify-content: center;
        color: #CC1420;
        font-size: 2.2rem;
        line-height: 1rem;
        font-weight: 700;
        margin-bottom: 30px;
      }

      h6 {
        display: flex;
        justify-content: center;
        font-size: 1rem;
      }

      .card-icon {
        display: flex;
        justify-content: center;
        font-size: 1.8rem;
        color: #CC1420;
        margin-bottom: 10px;
      }
    }
  }
}

#thirdSection {
  width: 100%;
  height: 1580px;
  overflow: hidden;

  position: relative;
  background-color: #FFFFFF;

  .thirdSection-background {
    background-color: #F3F3F3;
    width: 100%;
    height: 60vh;
    position: absolute;
    overflow: hidden;
  }

  .contentBox {
    width: 100%;
    max-width: 1299px;
    margin: 150px auto;
    position: relative;

    h2 {
      color: black;
      text-shadow: 2px 2px 4px white;
      font-size: 3.8rem;
      font-weight: 800;
      word-break: break-word;
    }

    .contentBox-sub {
      color: black;
      text-shadow: 2px 2px 4px white;
      font-size: 1.3rem;
    }
  }

  .imageArea {
    position: relative;
    width: 1299px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    top: -220px;
  }

  .imageBox {
    width: 580px;
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: #FFFFFF;
    padding: 30px 20px;

    h5 {
      font-size: 2rem;
    }

    h6 {
      font-size: 1.1rem;
    }

    &.firstBox {
      background: url("../../../../assets/img/index/zsms/zsms_img01.jpg") no-repeat center;
      background-size: cover;
    }

    &.secondBox {
      position: relative;
      top: -250px;
      background: url("../../../../assets/img/index/zsms/zsms_img02.jpg") no-repeat center;
      background-size: cover;
    }

    &.thirdBox {
      position: relative;
      top: -500px;
      background: url("../../../../assets/img/index/zsms/zsms_img03.jpg") no-repeat center;
      background-size: cover;
    }
  }
}

#fourthSection {
  width: 100%;
  height: 500px;
  overflow: hidden;

  position: relative;

  .fourthSection-background {
    width: 100%;
    height: 100%;
    background: url("../../../../assets/img/index/zsms/zsms_img04.jpg") no-repeat center;
    background-size: cover;
    filter: grayscale(70%);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    touch-action: none;
  }

  .contentBox {
    width: 100%;
    max-width: 1299px;
    margin: 0 auto;

    h2 {
      margin-top: 150px;
      color: white;
      text-shadow: 2px 2px 4px grey;
      font-size: 3.8rem;
      font-weight: 800;
      word-break: break-word;
    }

    .btnTxt {
      margin-top: 50px;
      display: block;
      color: white;
      position: relative;
      text-shadow: 2px 2px 4px black;
      font-size: 1.3rem;
    }

    .btnIcon {
      position: relative;
      width: 91px;
      margin-left: 20px;
      top: -27px;
      left: 450px;

      animation: indicateIntro 3s linear infinite;

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        left: 0;
        top: 50%;
        width: 72px;
        height: 14px;
        margin-top: -7px;
        background: url("../../../../assets/img/index/zsms/icon_btn_arror_right_white.png") no-repeat center center/contain;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 44px;
        top: 50%;
        width: 46px;
        height: 46px;
        margin-top: -23px;
        border-radius: 100%;
        background: grey;
      }
    }
  }
}

@media (max-width: 998px) {
  #firstSection {
    .contentBox {
      h2 {
        margin-top: 10px;
        text-align: center;
        font-size: 2.667rem !important;
        line-height: 3.333rem !important;
      }

      .contentBox-sub {
        margin-top: 20px;
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }
  }

  #secondSection {
    height: 850px;

    .secondSection-headBox {
      width: 100%;
      padding: 0 30px;
      flex-direction: column;
      margin: 50px 0;

      h2 {
        width: 100%;
      }

      p {
        width: 100%;
        margin-top: 10px;
      }
    }

    .secondSection-cardArea {
      flex-direction: column;
      margin-top: 10px;

      .cardBox {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;

        .card-icon {
          font-size: 1.2rem;
          margin-bottom: 10px;
        }

        h5 {
          font-size: 1rem;
          margin-bottom: 10px;
        }

        h6 {
          font-size: 0.8rem;
        }
      }
    }
  }

  #thirdSection {
    height: 1000px;

    .contentBox {
      margin-top: 50px;
      .contentBox-textArea {
        margin: 0;
        padding: 20px;

        h2 {
          font-size: 2.667rem !important;
          line-height: 3.333rem !important;
          margin-bottom: 20px;

          p {
            margin: 0;
          }
        }
      }
    }

    .thirdSection-background {
      height: 1000px;
    }

    .imageArea {
      margin: 0 auto;
      width: 80%;
      top: 0;
    }

    .imageBox {
      width: 0;
      height: 0;
      padding: 10px 20px;

      h5 {
        width: 100%;
        text-align: right;
        font-size: 1.7rem;
      }

      h6 {
        font-size: 0.9rem;
      }

      &.firstBox {
        width: 350px;
        height: 170px;
        top: 0;
        margin-bottom: 20px;
      }

      &.secondBox {
        width: 350px;
        height: 170px;
        top: 0;
        margin-bottom: 20px;
      }

      &.thirdBox {
        width: 350px;
        height: 170px;
        top: 0;
        margin-bottom: 20px;
      }
    }
  }

  #fourthSection {
    height: 530px;
    .contentBox {
      margin-top: 70px;
      padding: 0 20px;

      h2 {
        font-size: 2.667rem !important;
        line-height: 3.333rem !important;
        margin-top: 50px;

        p {
          margin: 0;
        }
      }

      .btnIcon {
        position: relative;
        width: 91px;
        margin-left: 20px;
        top: 30px;
        left: -20px;

        animation: indicateIntro 2s linear infinite;

        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          left: 0;
          top: 50%;
          width: 72px;
          height: 14px;
          margin-top: -7px;
          background: url("../../../../assets/img/index/zsms/icon_btn_arror_right_white.png") no-repeat center center/contain;
        }

        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 44px;
          top: 50%;
          width: 46px;
          height: 46px;
          margin-top: -23px;
          border-radius: 100%;
          background: #CD1521;
        }
      }
    }
  }

  #fifthSection {
    padding: 50px 20px;
  }
}

@keyframes indicateIntro {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}