@import "../../../../../styles/temp2/temp2";

.firstSection {
  .side-rowFlexBox {
    display: flex;
    justify-content: space-between;
    width: 100%;

    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    .side-contentsColumn {
      flex-direction: column;
      width: 33%;
      height: 130px;
      padding: 5px 0;
      justify-content: center;
      border-bottom: none;

      .contentsColumn-topHeader {
        flex-direction: row;
        margin-bottom: 10px;
      }

      .contentsColumn-bottomValue {
        margin-bottom: 10px;
      }
    }

    .fieldset {
      margin: 20px;
      width: 50%;
      border: 1px solid $alert-color;
      padding-left: 15px;
      border-radius: 5px;

      legend {
        width: auto;
        font-size: 14px;
        color: $alert-color;
      }

      .value {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color: $alert-color;
        height: 100%;
        padding: 0 15px 21px 0;
      }
    }

    .description {
      margin: 20px;
      width: 50%;
      font-size: 14px;
    }

    .button {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}


@media (max-width: 998px) {
  .fullContainer {
    height: 100vh;
  }

  .numberCell {
    width: 18%;
  }

  .dateCell {
    width: 18%;
  }
}