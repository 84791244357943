@import "/src/styles/temp1/temp1";

.cont {
  padding: 10px;
  display: flex;
  width: 300px;
  flex-wrap: wrap;

  .btn {
    width: 20px;
  }
}
