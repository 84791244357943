@import "../../../../styles/temp2/temp2";

.firstSection {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .section-side {
    width: 49.5%;

    .side-contentsColumn {
      &:last-child {
        border-bottom: none;
      }
    }

    .contentsColumn-topHeader {
      width: 33.33%;
    }

    .contentsColumn-bottomValue {
      width: 33.33%;
    }
  }
}

.secondSection {
  display: flex;
  width: 100%;

  .section-side {
    width: 100%;

    .contentsColumn-topHeader {
      width: 20%;
    }

    .contentsColumn-bottomValue {
      width: 20%;
    }
  }
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
  }

  .firstSection {
    flex-wrap: wrap;

    .section-side {
      width: 100%;

      .contentsColumn-leftHeader {
        font-size: 0.8rem;
      }

      .contentsColumn-topHeader {
        font-size: 0.8rem;
      }

      .contentsColumn-bottomValue {
        font-size: 0.8rem;
      }

      .contentsColumn-rightValue {
        font-size: 0.8rem;
      }

      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  .secondSection {
    .contentsColumn-topHeader {
      font-size: 0.8rem;
    }

    .contentsColumn-bottomValue {
      font-size: 0.8rem;
    }
  }

  .box__headerCell {
    width: calc((100% - 180px) / 2) !important;
  }

  .box__dateCell {
    width: 120px !important;
    text-align: center;
  }

  .box__statusCell {
    width: 70px !important;
  }

  .box__pointCell {
    width: calc((100% - 190px) / 2) !important;
    padding-right: 5px !important;
  }

  .box__hidden {
    display: none !important;
  }
}
