@import "/src/styles/temp2/temp2";

.titleBar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.secondSection {
  margin-top: 70px;
}

@media (max-width: 998px) {
  .fullContainer {
    padding: 5px !important;
  }

  .titleBar-left {
    width: 40% !important;
  }

  .titleBar-right {
    width: 60% !important;
  }
}