@import "/src/styles/temp1/colors.module";

.buttonOne {
  background-color: $buttonOne-color !important;
  color: $white-color !important;

  &:hover {
    background-color: $buttonOne-hover-color !important;
    color: $white-color !important;
  }

  &:disabled {
    background-color: $border-color !important;
    color: $white-color !important;
  }
}

.buttonTwo {
  background-color: $white-color !important;
  color: $buttonOne-color !important;
  border: 1px solid $buttonOne-color !important;

  &:hover {
    background-color: $buttonOne-hover-color !important;
    color:  $white-color !important;
    border: 1px solid $buttonOne-color !important;
  }
}

.buttonFour {
  background-color: $buttonThree-color !important;
  color: $white-color !important;
  border: 1px solid $buttonThree-color !important;

  &:hover {
    background-color: $white-color !important;
    color: $buttonThree-color !important;
    border: 1px solid $buttonThree-color !important;
  }
}

.buttonThree {
  background-color: $white-color !important;
  color: $red-color !important;
  border: 1px solid $red-color !important;

  &:hover {
    background-color: $red-color !important;
    color:  $white-color !important;
    border: 1px solid $red-color !important;
  }
}