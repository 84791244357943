@import "/src/styles/temp1/temp1";

.secondSection {
  margin-top: 15px;
}

.thirdSection {
  margin-top: 15px;
}

.side-contentsColumn {
  height: 56px;
}

.dateCell {
  width: 20%;
}

.pointCell {
  width: 20%;
}

.chargeCell {
  width: 20%;
}

.chargePointCell {
  width: 20%;
}

.spendCell {
  width: 20%;
}