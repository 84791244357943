@import "../../../../../styles/temp2/temp2";

.box__statusCell {
  width: 100px;
}

.box__cell {
  font-size: 14px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.box__statusCell {
    width: 100px;
  }

  &.right {
    align-items: flex-end;
    padding-right: 70px;
  }
}


@media (max-width: 998px) {
  .box__statusCell {
    width: 80px !important;
  }

  .box__mobileCell {
    width: calc((100% - 160px) / 2) !important;
    text-align: center;
  }

  .right {
    padding-right: 0 !important;
  }

  .box__hidden {
    display: none !important;
  }
}