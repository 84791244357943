@import "/src/styles/temp2/colors.module";

.buttonOne {
  background-color: $buttonOne-color !important;
  color: $white-color !important;

  &:hover {
    background-color: $buttonOne-hover-color !important;
    color: $white-color !important;
  }

  &:disabled {
    background-color: $border-color !important;
    color: $white-color !important;
  }
}