@import "src/styles/table";

.numCell {
  width: 20%;
  display: none;
}

.titleCell {
  width: 75%;
  text-align: left;
}

.dateCell {
  width: 25%;
}

.cont-notice img {
  max-width: 100%;
}

@media (max-width: 998px) {
  .titleCell {
    width: 75% !important;
  }

  .hiddenCell {
    display: none !important;
  }
}