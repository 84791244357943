@import "/src/styles/temp1/temp1";

.fullContainer {
  padding-top: 0;
}

.secondSection {
  margin-top: 15px;
}

.thirdSection {
  margin-top: 70px;
}

.fourthSection {
  margin-top: 15px;
}

.box-rightMargin {
  margin-right: 15px;
}

.codeCell {
  width: 20%;
}

.dateCell {
  width: 20%;
  white-space: pre;
  text-align: center;
}

.statusCell {
  width: 40%;
}

.funcCell {
  width: 10%;
  flex-direction: row !important;
}

.fontcolor-blue {
  color: $blue-color;
}

.fontcolor-red {
  color: $red-color;
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
  }

  .thirdSection {
    height: 56px;
  }

  .side-rowBox {
    flex-wrap: wrap;
  }

  .box-rightMargin {
    margin-right: 0;
    margin-bottom: 15px;
  }
}