@import "/src/styles/temp2/temp2";

.pagination {
  display: flex;
  margin: 30px 50px;
}

@media (max-width: 998px) {
  .pagination {
    display: flex;
    justify-content: center;
  }
}