@import "src/styles/table";

.table {
  .col-id {
    width: 10%;
  }

  .col-user {
    width: 40%;
  }

  .col-balance {
    width: 30%;
  }

  .col-created-at {
    width: 20%;
  }
}

@media (max-width: 998px) {
  .table {
    .col-id {
      display: none;
    }

    .col-user {
      width: 70%;
    }

    .col-balance {
      width: 30%;
    }

    .col-created-at {
      display: none;
    }
  }
}
