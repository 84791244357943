@import "../../../../styles/temp2/temp2";
.fullContainer {
  height: 100vh;
}

.side-contentsColumn {
  &:last-child {
    border-bottom: none;
  }
}

.contentsColumn-topHeader {
  &.topBox {
    width: 30%;
  }

  &.statusCell {
    width: 30%;
  }
}

.contentsColumn-bottomValue {
  &.topBox {
    align-items: flex-start !important;
  }

  &.statusCell {
    width: 30%;
  }
}

@media (max-width: 998px) {

}

