@import "/src/styles/temp1/temp1";

.fullContainer {
  padding-top: 0;
}

.secondSection {
  margin-top: 15px;
}

.numberCell {
  width: 10%;
}

.activateCell {
  width: 10%;
}

.userCell {
  width: 30%;
}

.balanceCell {
  width: 20%;
}

.priceCell {
  width: 20%;
}

.funcCell {
  width: 10%;
}

