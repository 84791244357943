@import "/src/styles/temp1/temp1";

.searchColumn-input {
  width: 250px;
  margin-right: 10px;
}

.searchColumn-button {
  width: 70px;
  margin-right: 10px;
}

.cell-titleText {
  font-size: 12px;
  color: $headerText-color;
}