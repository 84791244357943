@import "/src/styles/temp1/temp1";

.hover {
  min-height: 62.5px !important;
}

.titleBar-left {
  width: 60% !important;
  font-size: 18px;
}

.titleBar-right {
  width: 40% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-cell {
    width: 25%;
    color: $point-color;

    .cell-titleText {
      font-size: 12px;
      color: $text-color;
    }
  }
}


.secondSection {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .section-side {
    width: 32.3%;

    &.secondSection-side {
      width: 66.6%;

      .side-rowBox {
        justify-content: space-between;

        .side-flexBox {
          width: 49.2%;
        }
      }
    }
  }

  .empty {
    height: 56px;
    justify-content: center;
  }

  .contentsColumn-topHeader {
    height: 58px;

    &.dateCell {
      width: 115px;
    }

    &.descCell {
      flex-grow: 1;
    }

    &.valueCell {
      width: 60px;
    }

    &.fifty {
      width: 50%;
    }
  }

  .contentsColumn-bottomValue {
    height: 59px;

    &.dateCell {
      width: 115px;
    }

    &.descCell {
      flex-grow: 1;
    }

    &.valueCell {
      width: 60px;
      align-items: flex-end;
    }

    &.fifty {
      width: 50%;
    }
  }

  .contentsColumn-topBox {
    width: 100%;
    display: flex;
    flex-direction: column;

    .topBox-textField {
      margin-top: 20px;
    }


    .topBox-bottom {
      width: 100%;
      display: flex;
      margin: 15px 0 20px;
      justify-content: space-between;

      .bottom-left {
        width: 60%;
      }

      .bottom-right {
        width: 35%;
      }
    }

    .topBox-progressBox {
      font-size: 14px;
      color: #030303;
      display: flex;

      .progressBox__left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
      }

      .progressBox__right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        font-size: 13px;
      }
    }
  }
}

.topMargin {
  margin-top: 15px;
}

.cont-tel-reporter {
  border: 1px solid $border-color;
  border-radius: 3px;
  margin: 10px;
  padding: 10px;
  background: #fffefa;
  height: 283px;

  .box-tel-reporter {
    display: flex;
    flex-direction: column-reverse;
    padding: 10px 0;

    div {
      font-size: 0.9rem;
    }

    .msg {
      border: 1px solid $border-color;
      border-radius: 5px;
      padding: 5px;
      background: #f9fffd;
      margin-bottom: 10px;

      .msg-info {
        color: #7b7b7b;
        margin-bottom: 0;
      }
      .content {
        margin-top: 10px;
      }
    }
  }
}

.historyBox-height {
  height: calc(50% - 15px);
}

.aboutBox {
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  color: $red-color;
}

@media (max-width: 998px) {
  .fullContainer {
    padding: 10px;
  }
  .side-titleBar {
    flex-wrap: wrap;
  }

  .titleBar-left {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .titleBar-right {
    width: 100% !important;
  }

  .secondSection {
    flex-direction: column;
  }

  .section-side {
    flex-wrap: wrap;
    width: 100% !important;
  }

  .secondSection-side {
    flex-wrap: wrap;
  }

  .side-rowBox {
    margin-top: 0 !important;
    flex-direction: column;
    justify-content: unset !important;

    .side-flexBox {
      width: 100% !important;
      margin-top: 15px;
    }
  }
}
