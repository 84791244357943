@import "/src/styles/temp1/temp1";

.cont-scroll {
  background: #c4d0dc;
  border-radius: 5px;
  border: 1px solid $border-color;
  padding: 15px;
  height: calc(100vh - 400px);
  margin: 20px;
}

.cont-chat {
  display: flex;
  flex-direction: column-reverse;
}


.box {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &.box-admin {
    width: 100%;
    align-items: flex-start;
    .created-at {
      text-align: left;
    }
  }

  &.box-user {
    width: 60%;
    align-items: flex-end;

    .created-at {
      text-align: right;
    }
  }
}

.created-at {
  font-size: 0.7rem;
  color: #5c5c5c;
}

.user {
  .created-at {
    text-align: right;
  }
}

.cont-chat-box {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  width: 60%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  background: white;

  &.box-admin {
    margin-right: auto;
    background: #ffffff;

    .app-nm {
      font-size: 1rem;
      margin-bottom: 10px;
      font-weight: 900;
      color: gray;
    }

  }

  &.box-user {
    margin-left: auto;
    background-color: #ffea39;
  }
}

.table {
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 20px;

  .text {
    width: 11%;
    text-align: center;
    padding-right: 20px;
  }

  .input {
    width: 77%;
    padding-right: 20px;
  }

  .button {
    width: 12%;
  }
}

@media (max-width: 998px) {
  .text {
    width: 10% !important;
    padding-right: 0 !important;
    padding-bottom: 2px;
  }

  .input {
    width: 65% !important;
    padding-right: 5px !important;
  }

  .button {
    width: 20% !important;
  }
}