@import '../../../../styles/colors';

.box-title {
  display: flex;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid $border-color;

  div {
    flex-grow: 1;
    padding-left: 15px;
    font-size: 1.2rem;
  }

  svg {
    width: 20px;
    margin-top: 12px;
    margin-right: 10px;
    color: #b1b1b1;
    cursor: pointer;

    &:hover {
      color: #4e6773;
    }
  }
}
