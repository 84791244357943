@import "/src/styles/temp1/temp1";

.balanceHeader {
  width: 30%;
  border-right: 1px solid $border-color;
}

.balanceValue {
  width: 70%;
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding-left: 30px;
  color: $black-color;
}

.alertColor {
  margin-left: 20px;
  color: $red-color;
}

.marginBottom {
  margin-bottom: 30px;
}