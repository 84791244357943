.cont {
  position: fixed;
  width: 80%;
  left: 10%;
  top: 100px;
  z-index: 100;

  .cont-alert {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.07);

    .box {
      display: flex;
      width: 100% !important;

      .space {
        flex-grow: 1;
      }
    }
  }
}
