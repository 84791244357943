@import "/src/styles/temp1/temp1";

.secondSection {
  margin-top: 15px;
}

.side-contentsColumn {
  height: 56px;
}

.contentsColumn-leftHeader {
  width: 30%;
  text-align: center;
  border-right: 1px solid $border-color;
}

.contentsColumn-rightValue {
  width: 70%;
}

.thirdSection {
  margin-top: 70px;
}

.fourthSection {
  margin-top: 15px;
}

.lineCell {
  width: 33.33%;
}

.priceCell {
  width: 33.33%;
}

.valueCell {
  width: 33.33%;
}

.fifthSection {
  margin-top: 70px;
}

.sixthSection {
  margin-top: 15px;
}

.dateCell {
  width: 24%;
}

.statusCell {
  width: 16%;
}

.prevPointCell {
  width: 20%;
}

.changePointCell {
  width: 20%;
}

.lastPointCell {
  width: 20%;
}

@media (max-width: 998px) {
  .fullContainer {
    padding: 10px;
  }
  .side-contentsColumn {
    height: 56px;
    padding: 0 !important
  }

  .idCell {
    width: 37%;
  }

  .gradeCell {
    width: 18%;
  }

  .balanceCell {
    width: 22%;
  }

  .passwdCell {
    width: 23%;
  }

  .dateCell {
    width: 24%;
    display: none !important;
  }

  .statusCell {
    width: 25%;
  }

  .prevPointCell {
    width: 25%;
  }

  .changePointCell {
    width: 25%;
  }

  .lastPointCell {
    width: 25%;
  }
}
