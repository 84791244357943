@import "/src/styles/temp1/temp1";

.titleBar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.secondSection {
  margin-top: 18px;
  height: calc(100vh - 200px);
}

.marginRight {
  margin-right: 10px;
}

@media (max-width: 998px) {
  .fullContainer {
    padding: 5px !important;
  }

  .side-rowBox {
    flex-wrap: wrap;
  }

  .marginRight {
    margin-right: 0;
  }

  .marginTop {
    margin-top: 25px;
  }

  .titleBar-left {
    width: 40% !important;
  }

  .titleBar-right {
    width: 60% !important;
  }
}