.pagerColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .pagerColumn-pager {
    width: 50%;
  }

  .pagerColumn-button {
    width: 50%;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 42px;
  }
}

@media (max-width: 998px) {
  .pagerColumn {
    flex-direction: column-reverse;
    flex-wrap: wrap;

    .pagerColumn-pager {
      width: 100%;
    }

    .pagerColumn-button {
      width: 100%;
      padding-top: 30px;
      padding-right: 32px;
      margin-right: 0;
    }
  }
}