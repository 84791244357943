@import "colors";

.table {
  display: flex;

  div {
    border-top: 1px solid $border-color;
    padding: 10px;
    text-align: center;
    line-height: 35px;
    overflow: hidden;
    text-wrap: normal;
    text-overflow: ellipsis;

    div {
      border-top: none;
      padding: 0;
      text-align: left;
      line-height: normal;
    }

    input {
      margin-top: 3px;
    }
  }

  &.hover {
    &:hover {
      background: #f6f6f6;
    }
  }

  &.selected {
    background: rgb(241, 242, 250);
  }

  &.pointer {
    cursor: pointer;
  }

  .space {
    flex-grow: 1;
  }

  .mb-hidden {
    display: block;
  }

  &.border-bottom {
    border-bottom: 1px solid $border-color;
  }
}

.table-title {
  height: 60px;
  line-height: 80px;
  font-size: 1.1rem;
  color: #9a9a9a;
}


@media(max-width: 998px) {
  .table {
    flex-wrap: wrap;
    font-size: 0.8rem;

    div {
      padding: 5px 10px 5px 10px;

      svg {
        margin-top: -5px;
        color: #9c9c9c;
      }
    }

    .mb-hidden {
      display: none;
    }
  }
}
