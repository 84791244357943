@import "/src/styles/temp1/temp1";
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

.header {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //color: $text-color;
  //border-bottom: 1px solid $border-color;
  position: fixed;
  z-index: 1000;
}

.header__navBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 0 auto;

  .navBar__container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .navBar__logo {
      width: 20%;
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 24px;
      font-weight: 900;
      color: $point-color;
    }

    .navBar__leftMenu {
      color: black;
      display: flex;
      flex-grow: 1;
      align-items: center;
      font-size: 1.2rem;
    }

    .navBar__rightMenu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $text-color;
    }

    .btn {
      flex-grow: 1;
      font-size: 1.2rem;
      text-align: right;
      align-items: center;
      -ms-user-select: none;
      -webkit-user-select: none;
      user-select: none;

      &:last-child {
        margin-right: 50px;
      }
    }
  }
}

.contents {
  min-height: calc(100vh);
  background-color: #f4f5f8;
}

.logout {
  cursor: pointer;
}
