@import "src/styles/table";

.cont-title {
  display: flex;

  .half {
    width: 50%;
    padding-right: 10px;

    button {
      margin-top: 25px;
      width: 100px;
    }
  }
}

.sub-title {
  margin-bottom: 10px;
  color: #9a9a9a;
  font-size: 0.9rem;
}


.table {
  .send-date {
    width: 20%;
  }

  .content {
    width: 50%;
    text-align: left;
  }

  .delete {
    width: 10%;
  }

  .api {
    width: 10%;
  }

  .sent-sms {
    text-align: right;
    width: 10%;
  }
}

.notice {
  margin-top: 40px;
  border: 1px dashed #a71d2a;
  border-radius: 20px;
  padding: 20px;
  background-color: #ffe3e5;

  .title {
    color: #a71d2a;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  .text {
    color: #303030;
  }
}


@media (max-width: 998px) {
  .table {
    .send-date {
      width: 40%;
    }

    .content {
      width: 45%;
    }


    .delete {
      width: 15%;
    }

    .api {
      display: none;
    }

    .sent-sms {
      display: none;
    }
  }
}
