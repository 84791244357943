@import "colors.module";

.hover {
  cursor: pointer;

  &:hover {
    -webkit-animation: text-shadow-drop-center 0.6s both;
    animation: text-shadow-drop-center 0.6s both;
  }
}

@-webkit-keyframes text-shadow-drop-center {
  0% {
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
    color: $white-color;
  }
}
@keyframes text-shadow-drop-center {
  0% {
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
    color: $white-color;
  }
}