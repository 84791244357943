@import "/src/styles/temp2/temp2";

.cont-scroll {
  background: $background-color;
  border-radius: 5px;
  border: 1px solid $border-color;
  padding: 15px;
  height: 400px;
  margin: 20px;
}

.cont-chat {
  display: flex;
  flex-direction: column-reverse;
}


.cont-chat-box {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 60%;
  padding: 10px;
  border: 1px solid $border-color;
  margin-bottom: 20px;
  background: white;

  &.box-admin {
    margin-right: auto;
    background: $white-color;

    .app-nm {
      font-size: 1rem;
      margin-bottom: 10px;
      font-weight: 900;
      color: gray;
    }

  }

  &.box-user {
    margin-left: auto;

  }

  .created-at {
    text-align: right;
    font-size: 0.8rem;
    color: #bfbfbf;
  }
}

.table {
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 20px;

  .text {
    width: 8%;
    text-align: center;
    padding-right: 20px;
  }

  .input {
    width: 84%;
    padding-right: 20px;
  }

  .button {
    width: 8%;
  }
}

@media (max-width: 998px) {
  .text {
    width: 10% !important;
    padding-right: 0 !important;
    padding-bottom: 2px;
  }

  .input {
    width: 70% !important;
    padding-right: 5px !important;
  }

  .button {
    width: 15% !important;
  }
}