@import "/src/styles/temp2/temp2";

.fullContainer {
  margin: 0 auto;
  padding-top: 30px;
  width: 100%;
  max-width: 1140px;
  min-width: 998px;
  display: flex;

  .fullContainer__nav {
    width: 220px;
    margin-right: 20px;

    .nav__contentBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border: 1px solid $border-color;
      border-radius: 14px;
      padding: 10px 0;

      .contentBox__content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 30px;
        cursor: pointer;

        .icon {
          width: 20%;
          display: flex;
          align-items: center;
        }

        .text {
          width: 80%;
          margin-left: 20px;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }

  .box-contents {
    min-height: 100%;
    width: calc(100% - 220px);
  }
}
