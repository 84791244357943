@import "colors.module";

.fullContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $text-color;
  padding: 30px 0;
}

.section-side {
  display: flex;
  flex-direction: column;
}

.side-flexBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid $border-color;
  background-color: #ffffff;
}

.side-titleBar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid $border-color;

  .titleBar-icon {
    width: 44px;
    height: 44px;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $point-color;
    color: #ffffff;
    border-radius: 8px;
    margin: 0 20px;
  }

  .titleBar-text {
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text-main {
      font-size: 1.2rem;
    }

    .text-sub {
      font-size: 0.7rem;
      color: $headerText-color
    }
  }
}

.side-contentsColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;
  height: 53px;
}

.contentsColumn-leftHeader {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $headerText-color;
  font-size: 14px;
}

.contentsColumn-rightValue {
  width: 70%;
  font-size: 14px;
}

.contentsColumn-topHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $headerText-color;
  font-size: 14px;
}

.contentsColumn-bottomValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
    height: 100vh;
  }

  .firstSection {
    flex-wrap: wrap;

    .section-side {
      width: 100%;

      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}