@import "colors.module";

.fullContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $text-color;
  padding: 15px 0 30px;
}

.section-side {
  display: flex;
  flex-direction: column;

  .side-flexBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: $white-color;
  }

  .side-titleBar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 15px 20px;
    font-size: 18px;

    .titleBar-left {
      width: 50%;
      display: flex;
      align-items: center;
      color: $point-color;
    }

    .titleBar-right {
      width: 50%;
    }
  }

  .side-title {
    font-size: 16px;
    padding: 20px;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;

    .title-left {
      width: 40%;
      display: flex;
      align-items: center;
    }

    .title-right {
      width: 60%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .borderBottom {
    border-bottom: 1px solid $border-color;
  }

  .borderRight {
    border-right: 1px solid $border-color;
  }
}

.side-contentsColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0 20px;
  min-height: 56px;

  &:last-child {
    border-bottom: none;
  }

  &.headerColumn {
    border-bottom: 1px solid $border-color;
  }

  .contentsColumn-topHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $headerText-color;
    font-size: 14px;
  }

  .contentsColumn-bottomValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .contentsColumn-leftHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $headerText-color;
    font-size: 14px;
  }

  .contentsColumn-rightValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

.emptyColumn {
  text-align: center;
  flex-grow: 1;
}

.side-rowBox {
  display: flex;
  justify-content: space-between;
}

.side-searchColumn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .searchColumn-input {
    width: 250px;
    margin-right: 10px;
  }

  .searchColumn-button {
    width: 70px;
    margin-right: 10px;
  }
}