@import "../../../../styles/table";
@import "../../../../styles/colors";

.cont {
  margin: 0 auto 30px auto;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;

  .flex-column {
    display: flex;
    flex-direction: column;

    .space {
      flex-grow: 1;
    }
  }

  .box {
    position: relative;
    height: 100%;
    $width: 67%;
    $left: 17%;

    &:nth-last-child {
      margin-right: 0;
    }

    .cont-iphone {
      position: relative;
      text-align: center;
      padding-top: 60px;

      img {
        width: 88%;
        -webkit-user-drag: none;
      }

      textarea {
        width: $width;
        position: absolute;
        top: 27%;
        left: $left;
        resize: none;
        height: 47%;
        border: none;
        background: transparent;
        font-size: 1.1rem;
      }

      .iphone-title {
        -webkit-user-drag: none;
        text-align: center;
        position: absolute;
        top: 14.5%;
        width: 100%;
        font-weight: 900;
      }

      .cont-emoji {
        position: absolute;
        width: $width;
        left: $left;
        //border: 1px solid gray;
        height: 50px;
        bottom: 20%;
        text-align: right;
        padding-top: 15px;
      }
    }

    .box-panel {
      min-height: 1020px;
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 1px solid $border-color;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);
      padding: 20px;

      textarea {
        resize: none;
        height: 200px;
        margin-bottom: 10px;
        overflow: scroll;
      }

      .box-split {
        display: flex;
        margin-bottom: 5px;
      }


      .divider {
        display: flex;
        align-items: center;
        div {
          width: 50%;
        }
      }
      .box-title {
        font-size: 1.05rem;
        font-weight: 100;
        color: #3555b4;
        margin-bottom: 10px;

        &.red {
          color: indianred;
        }
      }

      .info {
        font-size: 0.9rem;
        color: gray;
        margin-bottom: 10px;
      }

      .cont-tel-reporter {
        border: 1px solid $border-color;
        border-radius: 3px;
        padding: 0 10px 0 10px;
        background: #fffefa;
        height: 280px;


        .box-tel-reporter {
          display: flex;
          flex-direction: column-reverse;
          padding: 10px 0 10px 0;

          div {
            font-size: 0.9rem;
          }

          .msg {
            border: 1px solid $border-color;
            border-radius: 5px;
            padding: 5px;
            background: #f9fffd;
            margin-bottom: 10px;

            .msg-info {
              color: #7b7b7b;
              margin-bottom: 0;
            }
            .content {
              margin-top: 10px;
            }
          }

        }




      }
    }
  }
}

.dateCell {
  width: 40%;
}

.descCell {
  width: 40%;
}

.volumeCell {
  width: 20%;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.box-radio {
  display: flex;

  &:nth-child(1) {
    width: 150px;
  }

  &:nth-child(2) {
    flex-grow: 1;
    text-align: left;
  }
}


@media (max-width: 998px) {
  .cont {
    padding: 10px;
    flex-wrap: wrap;

    .box {
      width: 100% !important;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .dateCell {
    width: 50% !important;
  }

  .descCell {
    width: 50% !important;
  }

  .hiddenCell {
    display: none !important;
  }
}
