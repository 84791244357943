.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-height: 1200px;
}

@media (max-width: 998px) {
  min-height: 100vh !important;
}
