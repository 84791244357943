@import "/src/styles/temp1/temp1";

.titleBar-left {
  width: 53% !important;
  font-size: 18px;
}

.titleBar-right {
  width: 47% !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.side-contentsColumn {
  min-height: 56px;
}

.dateCell {
  width: 18%;
}

.hover {
  &:hover {
    -webkit-animation: text-shadow-drop-center 0.6s both;
    animation: text-shadow-drop-center 0.6s both;
  }
}

.descCell {
  width: 56%;
  text-align: left;
  padding-left: 20px;
  align-items: flex-start;
  word-break: break-word;
}

.valueCell {
  width: 13%;
}

.funcCell {
  width: 13%;
}

.fontcolor-red {
  color: $red-color;
}


@media (max-width: 998px) {
  .fullContainer {
    padding: 5px;
  }

  .dateCell {
    width: 18%;
  }

  .lineCell {
    width: 18%;
  }

  .valueCell {
    width: 18%;
  }
}