@import "/src/styles/temp1/temp1";

.fullContainer {
  min-height: 100vh;
}

.secondSection {
  margin-top: 15px;
}

.side-contentsColumn {
  height: 56px;
  padding: 0 !important
}

.numCell {
  width: 20%;
}

.titleCell {
  width: 60%;
}

.dateCell {
  width: 20%;
}

.fullContainer {

  .fullContainer__title {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title__leftSection {
      color: #0768ac;
      font-size: 20px;
      width: 70%;
    }
  }

  .fullContainer__detail {
    display: flex;
    width: 100%;

    .detail__firstSection {
      width: 100%;
      display: flex;
      flex-direction: column;

      .firstSection__box {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        font-size: 14px;
        padding-bottom: 30px;

        display: flex;
        flex-direction: column;

        .box__column {
          width: 100%;
          height: 56px;
          display: flex;

          &.hover {
            &:hover {
              background: #f6f6f6;
            }
          }

          .box__headerCell {
            font-weight: 900;
            font-size: 14px;
            border-bottom: 1px solid #aeaeae;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.box__amountCell {
              width: 150px;
            }
          }

          .box__cell {
            font-size: 14px;
            border-bottom: 1px solid #dbdbdb;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.box__amountCell {
              width: 150px;
              align-items: flex-end;
              padding-right: 30px;
            }
          }

          .box__dateCell {
            width: 200px;
          }

          .box__detailCell {
            text-align: left;
            padding-left: 20px;
            align-items: flex-start;
          }

          .box__lineCell {
            width: 150px;
          }
        }
      }
    }
  }
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;

    .title__leftSection {
      width: 100%;
      flex-wrap: wrap;
    }

    .fullContainer__detail {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  .box__dateCell {
    width: 110px !important;
    text-align: center;
  }

  .box__detailCell {
    padding-left: 5px !important;
  }

  .box__lineCell {
    width: 60px !important;
  }

  .box__amountCell {
    width: 60px !important;
    align-items: center !important;
    padding-right: 0 !important;
  }

  .box__hidden {
    display: none !important;
  }
}