@import "../../../../styles/colors";

.cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    border: 1px solid $border-color;
    background: white;
    max-width: 90vw;
    width: 500px;
    max-height: 700px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
    overflow-y: scroll;
    overscroll-behavior-x: none;
    overscroll-behavior-y: auto;
    display: flex;
    flex-direction: column;

    .relative {
      position: relative;
      margin-bottom: 20px;

      .contents {
        flex-grow: 1;
        img {
          width: 100%;
        }
      }

      .footer {
        position: absolute;
        bottom: -20;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.8);
        border-top: 1px solid $border-color;
        height: 50px;
      }
    }

    .title {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid $border-color;
      padding: 0 10px 0 10px;
      font-size: 1.2rem;
      color: #7e7e7e;
      display: flex;

      .title-contents {
        flex-grow: 1;
      }

      .day {
        &:hover {
          color: black;
        }
      }
    }
  }
}