@import "src/styles/table";

.dateCommCell {
  width: 20%;
}

.statusCell {
  width: 20%;
}

.methodCell {
  width: 20%;
}

.numberCommCell {
  width: 20%;
}

.sumCommCell {
  width: 20%;
}

.sumCell {
  width: 33.33%;
}

.completeCell {
  width: 33.33%;
}

.incompleteCell {
  width: 33.33%;
}

.dateCell {
  width: 15%;
}

.badgeCell {
  width: 15%;
}

.requestCell {
  width: 15%;
}

.agentCell {
  width: 30%;
}

.valueCell {
  width: 25%;
}

.percentCell {
  width: 13%;
}

.marginCell {
  width: 15%;
}

.commissionCell {
  width: 17%;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .numberCommCell {
    width: 20% !important;
  }

  .sumCommCell {
    width: 20% !important;
  }

  .hiddenCell {
    display: none !important;
  }
}