@import "src/styles/table";

.codeCell {
  width: 25%;
}

.dateCell {
  width: 20%;
  white-space: pre;
  text-align: center;
}

.lineCell {
  width: 40%;
}

.funcCell {
  width: 15%;
  flex-direction: row !important;
}

.textLeft {
  text-align: left !important;
}