$text-color: #59616d;
$point-color: #0065EF;
$headerText-color: #9d9d9d;

$header-color: #0065EF;
$border-color: #d9d9d9;

$hover-color: #0089FF;
$alert-color: #D64045;

$blue-color: #45a7ea;
$orange-color: #FF9116;
$red-color: #ff7177;
$white-color: #FFFFFF;
$black-color: #000000;

$buttonOne-color: #0685f4;
$buttonOne-hover-color: #0962e5;

$buttonThree-color: #ff7177;
$buttonThree-hover-color: #d04349;

$chip1-color: rgba(0, 101, 239, 0.7);
$chip2-color: rgba(214, 64, 69, 0.7);
$chip3-color: rgba(89, 97, 109, 0.7);
$chip4-color: rgba(0, 0, 0, 0.7);


$myPage-chip1-color: rgba(0, 0, 0, 0.7);
$myPage-chip2-color: rgba(214, 64, 69, 0.7);
$myPage-chip3-color: rgba(41, 167, 69, 0.7);
$myPage-chip4-color: rgba(118, 40, 167, 0.7);
$myPage-chip5-color: rgba(0, 101, 239, 0.7);
$myPage-chip6-color: rgba(229, 126, 33, 0.7);
$myPage-chip7-color: rgba(93, 207, 255, 0.7);

:export {
  textColor: $text-color;
}