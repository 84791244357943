.cont {
  width: 50vw;
  min-height: 100vh;
  background: #fff7e8;
}

.title {
  font-size: 1.2rem;
  padding: 10px;
}


.menu {
  padding: 10px 10px 10px 20px;
  font-size: 1rem;
  color: #7e7e7e;
}
