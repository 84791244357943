@import "src/styles/colors";

.center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoArea {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .filter {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.cont {
  margin: 0 auto;
  padding: 40px;
  border-radius: 3px;
  box-shadow: 3px 3px 3px #d0d0d0;
  border: 1px solid #f5f5f5;
  background: #ffffff;
  min-width: 370px;
  width: 330px;

  p {
    margin-top: 10px;
    margin-bottom: 5px !important;
    font-size: 0.8rem;
  }

  form {
    height: 550px;
  }

  .info {
    font-size: 0.8rem;
    color: gray;
    margin-top: 10px;
  }

  .box-terms {
    display: flex;

    label {
      display: block;
      flex-grow: 1;
    }

    .check-box {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-right: 10px;
    }
  }

  .box-code {
    display: flex;

    select {
      width: 110px;
      margin-right: 10px;
      font-size: 0.9rem;
      height: 38px;
    }

    input {
      width: calc(100% - 110px);
    }
  }

  .cont-recapcha {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.copyright {
  text-align: center;
  margin: 30px 0 30px 0;
  font-size: 0.8rem;
  color: #a8a8a8
}

@media (max-width: 998px) {
  .cont {
    margin-top: 70px;
  }
}