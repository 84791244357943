@import "/src/styles/temp1/temp1";

.fullContainer {
  min-height: 0 !important;
}

.firstSection {
  margin-top: 25px;
}

.secondSection {
  margin-top: 15px;
}

.side-contentsColumn {
  height: 56px;
}

.statusCell {
  width: 10%;
}

.dateCell {
  width: 20%;
}

.depositorCell {
  width: 20%;
}

.pointCell {
  width: 20%;
}

.depositCell {
  width: 20%;
}

.cancelCell {
  width: 10%;
}

.fontcolor-red {
  color: $red-color;
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
    margin-top: 30px;
  }

  .firstSection {
    height: 57px;
  }

  .side-contentsColumn {
    padding: 0 !important;
  }

  .statusCell {
    width: 20% !important;
  }

  .dateCell {
    width: 20%;
    display: none !important;
  }

  .depositorCell {
    width: 30%;
  }

  .pointCell {
    width: 20%;
    display: none !important;
  }

  .depositCell {
    width: 30%;
  }

  .cancelCell {
    width: 20%;
  }
}