.cont {
  background: #000000;
  color: #ffffff;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textBox {
  width: 100%;
  max-width: 1299px;
  margin: 0 auto;
}
