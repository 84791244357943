@import "../../../../styles/temp2/temp2";


.fullContainer {
  margin: 0 auto;
}

.contentsColumn-topHeader {
  &.topBox {
    width: 33.33%;
  }
}

.contentsColumn-bottomValue {
  &.topBox {
    width: 33.33%;
  }
}

.dateCell {
  width: 15%;
}

.statusCell {
  width: 15%;
}

.agencyCell {
  width: 30%;
}

.sendCell {
  width: 20%;
}

.valueCell {
  width: 20%;
}

.alignRight {
  text-align: right !important;
  padding-right: 30px;
  align-items: flex-end !important;
}

@media (max-width: 998px) {
  .box__hidden {
    display: none !important;
  }
}