@import "../../../../styles/temp2/temp2";

.true {
  background-color: $point-color !important;
  color: #ffffff !important;
  font-size: 11px !important;
}

.false {
  background-color: $headerText-color !important;
  color: #ffffff !important;
  font-size: 11px !important;
}