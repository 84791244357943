@import "../../../../styles/temp2/temp2";

.numCell {
  width: 10%;
}

.titleCell {
  width: 80%;
}

.dateCell {
  width: 10%;
}


@media (max-width: 998px) {
  .numberCell {
    width: 18%;
  }

  .titleCell {
    width: 64%;
  }

  .dateCell {
    width: 18%;
  }
}