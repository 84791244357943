@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
  font-family: Nato Sans;
  src: url("../assets/fonts/nato-sans/NotoSansKR-Regular.otf");
}

@font-face {
  font-family: Nato Sans;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/nato-sans/NotoSansKR-Bold.otf");
}

@font-face {
  font-family: Nato Sans;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/nato-sans/NotoSansKR-Black.otf");
}

* {
  font-family: "Nato Sans", sans-serif;
}

// 인풋 박스 포커스때 테두리 삭제
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: unset;

  &:hover {
    text-decoration: none;
    color: unset;
  }
}


// col 모두 30 마진바텀
@for $i from 1 to 12 {
  .col-#{$i} {
    margin-bottom: 50px;
  }
}
