@import "/src/styles/temp1/temp1";

.fullContainer {
  min-height: 0 !important;
}

.secondSection {
  margin-top: 15px;
}

.side-rowBox {
  .section-side {
    width: 49.4%;

    .side-contentsColumn {
      height: 65px;
    }
  }
}

.leftBox {
  padding: 10px 0;
  height: 100%;
}

.marginTop {
  margin-top: 15px;
}

.contentsColumn-leftHeader {
  width: 30%;
  padding-left: 40px;
  align-items: flex-start !important;
}

.contentsColumn-rightValue {
  width: 63%;
  padding-right: 40px;
}

.resultBox {
  height: 100%;
  padding: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-left {
  width: 100% !important;
}

.infoColumn {
  height: 100% !important;
}

.aboutBox {
  margin: 20px;
  font-size: 14px;
}

.buttonBox {
  margin: 20px;
  text-align: right;
}

.alertColor {
  font-size: 40px;
  color: $alert-color;
}

.variableCell {
  width: 25%;
}

.balanceCell {
  width: 25%;
}

.pointCell {
  width: 25%;
}

.sumCell {
  width: 25%;
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
  }

  .firstSection {
    height: 57px;
  }

  .side-rowBox {
    flex-wrap: wrap;
    flex-direction: column !important;
  }

  .section-side {
    width: 100% !important;
    margin-top: 15px;
  }

  .contentsColumn-leftHeader {
    width: 35% !important;
    padding-left: 0 !important;
  }

  .contentsColumn-rightValue {
    width: 65% !important;
    padding-right: 0 !important;
  }

  .infoColumn {
    flex-direction: column !important;
  }

  .resultBox {
    padding: 30px 10px !important;
  }
}
