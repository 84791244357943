@import "src/styles/table";


.cont-finder {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  input {
    width: 200px;
    margin-right: 10px;
  }

  button {
    width: 80px;
  }
}

.numCell {
  width: 20%;
}

.activateCell {
  width: 25%;
}

.userCell {
  width: 30%;
}

.valueCell {
  width: 25%;
}

@media (max-width: 998px) {
  .activateCell {
    width: 25% !important;
  }

  .userCell {
    width: 40% !important;
  }

  .valueCell {
    width: 35% !important;
  }

  .hiddenCell {
    display: none !important;
  }
}