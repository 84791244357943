@import "src/styles/colors";

.cont-direct-notice {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    border-radius: 3px;
    background: white;
    max-width: 90vw;
    width: 400px;
    height: 500px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .title {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid $border-color;
      padding: 0 10px 0 10px;
      font-size: 1.2rem;
      color: #7e7e7e;
      display: flex;

      .title-contents {
        flex-grow: 1;
      }

      .day {
        &:hover {
          color: black;
        }
      }
    }

    .contents {
      flex-grow: 1;
      padding: 10px;

      img {
        width: 100%;
      }
    }

    .footer {
      border-top: 1px solid $border-color;
      height: 50px;
      line-height: 50px;
      text-align: right;
      padding-right: 10px;
    }
  }
}
