@import "src/styles/colors";

.cont {
  display: flex;
  max-width: 1200px;
  min-width: 998px;
  margin: 0 auto;

  .nav {
    position: relative;
    width: 250px;
    margin-right: 20px;

    .nav-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $main-color;
    }

    .title {
      height: 60px;
      line-height: 60px;
      font-size: 1.2rem;
      padding: 0 15px 0 15px;
      font-weight: 700;
      color: #aeaeae;
      text-align: center;
    }

    .box-btn {
      border: 1px solid $border-color;

      .btn-nav {
        $height: 60px;
        min-height: $height;
        line-height: $height;
        padding: 0 15px 0 15px;
        border-bottom: 1px solid $border-color;
        color: #cbcbcb;
        font-size: 1.1rem;
        cursor: pointer;

        &.title {
          color: $main-color;
        }

        &:nth-last-child {
          border-bottom: none;
        }
      }


      .cont-subs {
        background: #fafafa;
        overflow: hidden;

        .subs {
          cursor: pointer;
          color: #9c9c9c;
          padding-left: 20px;
          height: 40px;
          line-height: 40px;

          svg {
            margin-top: -4px;
          }

          &:hover {
            color: #515151;
          }
        }
      }

    }
  }


  .box-contents {
    min-height: 100%;
    //padding-top: 60px;
    width: calc(100% - 270px);
  }
}
