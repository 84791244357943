@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&display=swap');

.contentBox {
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  padding: 100px 50px 140px;
  position: relative;

  h2 {
    margin-top: 140px;
    font-size: 3.8rem;
    line-height: 4.706rem;
    text-align: left;
    text-shadow: 2px 2px 4px gray;

    color: #ffffff;
    font-weight: 700;
    font-family: "Nato Sans", sans-serif;
    word-break: break-word;
  }

  .contentBox-sub {
    color: #ffffff;
    text-shadow: 2px 2px 4px gray;
    margin-top: 45px;
    font-size: 1.059rem;
    line-height: 1.059rem;
    text-align: left;
  }
}

.contentBox-group {
  margin-top: 80px;
  color: #ffffff;

  .group-title {
    font-size: 0.824rem;
  }
  .group-process {
    width: 360px;
    height: 1px;
    margin: 20px 0;
    background-color: #555a61;
    position: relative;

    div {
      position: absolute;
      background-color: #ffffff;
      top: -1px;
      left: 0;
      height: 2px;
    }
  }

  .group-number {
    font-size: 1.059rem;
    font-weight: 700;
  }
}

.contentBox-earth {
  position: absolute;
  top: 30%;
  right: 190px;
  height: 624px;

.earth-motion {
  position: absolute;
  top: 0;
  left: 0;
  width: 698px;
  height: 624px;
  animation-duration: 1s;
  animation-fill-mode: both;

  .earth-img {
    position: absolute;
    top: 0;
    left: -295px;
    width: 698px;
    height: 624px;
    background: url("../../../../assets/img/index/default/img_earth.png") 50% 50% no-repeat;
  }

  .earth-lineOne {
    position: absolute;
    top: 0;
    left: -295px;
    width: 100%;
    height: 100%;
    background: url("../../../../assets/img/index/default/earth_line_front.png") 50% 50% no-repeat;
    animation: triSpin 80s infinite;
  }

  .earth-lineTwo {
    position: absolute;
    top: 0;
    left: -295px;
    width: 100%;
    height: 100%;
    background: url("../../../../assets/img/index/default/earth_line_back.png") 50% 50% no-repeat;
    animation: triSpin2 90s infinite;
  }
}

  ul {
    list-style: none;

    li {
      position: absolute;
      color: #ffffff;
      text-align: center;
      font-size: 1rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      width: 110px;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      transition: width .3s, height .3s, font-size .3s;

      &:hover {
        width: 160px;
        height: 160px;
        font-size: 1.647rem;
        background: #ffcb08;
      }
    }

    li.item1 {
      bottom: 514px;
      right: 80px;
    }

    li.item2 {
      bottom: 514px;
      right: -220px;
    }

    li.item3 {
      bottom: 254px;
      right: -370px;
    }

    li.item4 {
      bottom: 0;
      right: -220px;
    }

    li.item5 {
      bottom: 0;
      right: 80px;
    }

    li.item6 {
      bottom: 254px;
      right: 230px;
    }
  }
}

.contentBox-cardBox {
  position: absolute;
  color: #ffffff;
  top: 50%;
  right: -7%;

  ul {
    list-style: none;
    display: flex;

    li {
      color: #ffffff;
      font-size: 1rem;
      background-color: rgba(0, 0, 0, 0.5);
      width: 190px;
      height: 200px;
      display: flex;
      flex-direction: column;

      padding: 20px;
      margin-right: 20px;

      transition: width .3s, height .3s, font-size .3s;

      &:hover {
        width: 250px;
        height: 260px;
        font-size: 1.8rem;
        background: #0ca398;
      }

      .card-text {
        font-size: 0.98rem;
        word-break: keep-all;
        height: 80%;
      }

      .card-icon {
        height: 20%;
        text-align: right;
        font-size: 0.98rem;
      }
    }
  }
}

.contentBox-diamond {
  position: absolute;
  color: #ffffff;
  top: 30%;
  right: 5%;
  transform: rotate(45deg);

  display: flex;
  flex-direction: column;

  .diamond-column {
    display: flex;
    justify-content: space-between;

    .diamond-box {
      width: 184px;
      height: 184px;
      margin: 6px;
      background-color: rgba(0, 0, 0, 0.5);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      transition: .3s;

      &:hover {
        background: #184cde;
        transform: scale(1.36);
      }

      .diamond-text {
        transform: rotate(-45deg);
      }
    }
  }
}

.contentBox-indicator {
  position: absolute;
  bottom: 150px;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-50%);

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 32px;
    height: 32px;
    margin: -10px 0 0 -15px;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    transform: rotate(-45deg);
    animation: indicateIntro 1.5s infinite;
  }
}

#firstSection {
  width: 100%;
  height: 100vh;
  //background: url("../../../../assets/img/index/defaultSection1.jpg") no-repeat center;

  position: relative;
  touch-action: none;

  .contentBox {
    h2 {
      font-size: 4.706rem;
      line-height: 5.882rem;
      text-align: center;
    }
  }

  .contentBox-sub {
    margin-top: 84px;
    font-size: 1.294rem;
    text-align: center;
  }
}

#secondSection {
  background: url("../../../../assets/img/index/defaultSection2.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}
#mySmsSecondSection {
  background: url("../../../../assets/img/index/mySmsDefaultSection2.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}
#dubaiSecondSection {
  background: url("../../../../assets/img/index/dubaiDefaultSection2.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}


#thirdSection {
  background: url("../../../../assets/img/index/defaultSection3.jpg") no-repeat center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}
#mySmsThirdSection {
  background: url("../../../../assets/img/index/mySmsDefaultSection3.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}
#dubaiThirdSection {
  background: url("../../../../assets/img/index/dubaiDefaultSection3.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}

#fourthSection {
  background: url("../../../../assets/img/index/defaultSection4.jpg") no-repeat center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}
#mySmsFourthSection {
  background: url("../../../../assets/img/index/mySmsDefaultSection4.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}
#dubaiFourthSection {
  background: url("../../../../assets/img/index/dubaiDefaultSection4.jpg") no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  touch-action: none;
}

@media (max-width: 998px) {
  .contentBox {
    padding: 56px 40px 0;

    h2 {
      margin-top: 10px;
      font-size: 2rem !important;
      line-height: 2.48rem !important;
    }

    .contentBox-sub {
      margin-top: 30px;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .contentBox-group {
    margin-top: 20px;

    .group-process {
      margin: 10px 0;
      width: 100%;
    }
  }

  .contentBox-indicator {
    bottom: 50px;
    width: 30px;
    height: 30px;
  }

  .contentBox-earth {
    position: absolute;
    top: 33%;
    right: 50%;
    height: 500px;

    ul {

      li {
        font-size: 0.7rem;
        width: 70px;
        height: 70px;

      }

      li.item1 {
        top: 90px;
        left: -60px;
      }

      li.item2 {
        top: 90px;
        right: -100px;
      }

      li.item3 {
        top: 245px;
        right: -165px;
      }

      li.item4 {
        bottom: 150px;
        right: -100px;
      }

      li.item5 {
        bottom: 150px;
        left: -60px;
      }

      li.item6 {
        top: 245px;
        left: -120px;
      }
    }
  }
  .contentBox-cardBox {
    position: absolute;
    color: #ffffff;
    top: 52%;
    left: 0;

    transition: width .3s, height .3s;

    ul {
      flex-direction: column;

      li {
        font-size: 0.8rem;
        width: 82%;
        display: flex;
        flex-direction: row;
        height: auto;

        padding: 20px;
        margin-right: 0;
        margin-bottom: 10px;

        &:hover {
          width: 82%;
          height: 130px;
          font-size: 1.8rem;
          background: #0ca398;

          .card-text {
            display: flex;
            align-items: center;
          }

          .card-icon {
            display: flex;
            align-items: center;
          }
        }

        .card-text {
          font-size: 0.98rem;
          word-break: keep-all;
          width: 90%;
          height: 100%;
        }

        .card-icon {
          width: 10%;
          height: 100%;
          text-align: right;
        }
      }
    }
  }

  .contentBox-diamond {
    position: absolute;
    color: #ffffff;
    top: 55%;
    left: 19%;
    transform: rotate(45deg);
    width: 230px;

    display: flex;
    flex-direction: column;

    .diamond-column {
      display: flex;
      justify-content: space-between;

      .diamond-box {
        width: 110px;
        height: 110px;
        margin: 6px;
        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: .3s;

        &:hover {
          background: #184cde;
          transform: scale(1.36);
        }

        .diamond-text {
          transform: rotate(-45deg);
          font-size: 0.8rem;
        }
      }
    }
  }

  .firstSection {
    .contentBox {
      h2 {
        font-size: 2.667rem !important;
        line-height: 3.333rem !important;
      }
    }
  }

  .contentBox-earth {
    position: absolute;
    top: 30%;
    right: 190px;
    height: 624px;

    .earth-motion {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      height: 300px;
      animation-duration: 1s;
      animation-fill-mode: both;

      .earth-img {
        position: absolute;
        top: 150px;
        left: -70px;
        width: 250px;
        height: 250px;
        background: url("../../../../assets/img/index/default/img_earth.png") no-repeat;
        background-size: cover;
      }

      .earth-lineOne {
        position: absolute;
        top: 160px;
        left: -75px;
        width: 240px;
        height: 240px;
        background: url("../../../../assets/img/index/default/earth_line_front.png") no-repeat;
        background-size: cover;
        animation: triSpin 80s infinite;
      }

      .earth-lineTwo {
        position: absolute;
        top: 160px;
        left: -75px;
        width: 240px;
        height: 240px;
        background: url("../../../../assets/img/index/default/earth_line_back.png") no-repeat;
        background-size: cover;
        animation: triSpin2 90s infinite;
      }
    }
  }
}

@keyframes indicateIntro {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes triSpin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes triSpin2 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
