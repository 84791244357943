@import "src/styles/table";

.dateCell {
  width: 24%;
}

.statusCell {
  width: 16%;
}

.prevPointCell {
  width: 20%;
}

.changePointCell {
  width: 20%;
}

.lastPointCell {
  width: 20%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .dateCell {
    width: 40%;
  }

  .statusCell {
    width: 25%;
  }

  .lastPointCell {
    width: 35%;
  }

  .hiddenCell {
    display: none !important;
  }
}