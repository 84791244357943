@import "/src/styles/temp1/temp1";

.firstSection {
  width: 100%;
}

.secondSection {
  margin-top: 15px;
  width: 100%;
}

.thirdSection {
  margin-top: 15px;
}

.fourthSection {
  margin-top: 15px;
}

.side-titleBar {
  justify-content: space-between !important;
}

.titleBar-right {
  width: 10% !important;
}

.title-form {
  width: 30%;
}

.title-right {
  width: 100% !important;
}

.side-contentsColumn {
  height: 56px;
}

.contentsColumn-leftHeader {
  width: 30%;
  border-right: 1px solid $border-color;
}

.contentsColumn-rightValue {
  width: 70%;
  align-items: flex-start !important;
  padding-left: 80px;
}

.statusCell {
  width: 30%;
}

.numberCell {
  width: 70%;
}

@media (max-width: 998px) {
  .fullContainer {
    flex-wrap: wrap;
    padding: 10px;
  }

  .titleBar-right {
    width: 30% !important;
  }

  .contentsColumn-rightValue {
    padding-left: 15px;
  }

  .side-title {
    flex-direction: column !important
  }

  .title-right {
    margin-top: 15px;
    width: 100% !important;
  }
}

