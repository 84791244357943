@import "src/styles/table";


.table {
  .col-code {
    width: 25%;
  }

  .col-created-at {
    width: 25%;
  }

  .col-api {
    width: 25%;
  }

  .col-func {
    width: 25%;
  }
}
