@import "src/styles/table";
@import "src/styles/colors";

.cont-scroll {
  background: #f8f8f8;
  border-radius: 5px;
  border: 1px solid $border-color;
  padding: 15px;
  height: 400px;
  margin-bottom: 15px;
}

.cont-chat {
  display: flex;
  flex-direction: column-reverse;
}


.cont-chat-box {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 60%;
  padding: 10px;
  border: 1px solid #e2e2e2;
  margin-bottom: 20px;
  background: white;


  &.box-admin {
    margin-right: auto;
    background: #effff0;

    .app-nm {
      font-size: 1rem;
      margin-bottom: 10px;
      font-weight: 900;
      color: gray;
    }

  }

  &.box-user {
    margin-left: auto;

  }

  .created-at {
    text-align: right;
    font-size: 0.8rem;
    color: #bfbfbf;
  }
}
