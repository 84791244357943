@import "/src/styles/temp1/temp1";

.fullContainer {
  padding-top: 0;
}

.secondSection {
  margin-top: 15px;
}

.numberCell {
  width: 20%;
}

.activateCell {
  width: 20%;
}

.userCell {
  width: 30%;
}

.valueCell {
  width: 20%;
}

.hiddenCell {
  display: none;
}

.side-searchColumn {
  div {
    margin-right: 10px;
  }

  .searchColumn-input {
    width: 250px;
  }

  .searchColumn-button {
    width: 70px;
  }
}

@media (max-width: 998px) {
  .hiddenCell {
    display: none !important;
  }

  .buttonOne {
    font-size: 10px !important;
  }

  .buttonTwo {
    font-size: 10px !important;
  }
}