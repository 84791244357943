@import "../../../../styles/temp2/temp2";


.contentsColumn-topHeader {
  &.box__amountCell {
    width: 150px;
  }
}

.contentsColumn-bottomValue {
  &.box__amountCell {
    width: 150px;
    align-items: flex-end !important;
    padding-right: 30px;
  }

  &.box__detailCell {
    align-items: flex-start !important;
    padding: 0 30px;
  }
}

.box__dateCell {
  width: 200px;
}

.box__detailCell {
  text-align: left;
  padding-left: 20px;
  align-items: flex-start;
}

.box__lineCell {
  width: 150px;
}


@media (max-width: 998px) {
  .box__dateCell {
    width: 110px !important;
    text-align: center;
  }

  .box__detailCell {
    padding-left: 5px !important;
  }

  .box__lineCell {
    width: 60px !important;
  }

  .contentsColumn-topHeader {
    &.box__amountCell {
      width: 60px !important;
    }
  }
  .contentsColumn-bottomValue {
    &.box__amountCell {
      width: 60px !important;
      padding-right: 10px !important;
    }

    &.box__detailCell {
      padding: 0 10px !important;
    }
  }
}