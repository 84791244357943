@import "src/styles/colors";

.cont {
  padding: 15px;

  .box-title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #3555b4;
  }

  .red {
    color: crimson;
  }

  .info {
    color: gray;
    margin-bottom: 10px;
  }

  .box-check {
    display: flex;
    margin-bottom: 10px;

    .label {
      width: 150px;
      padding-left: 15px;
    }

    .gray {
      color: gray;
    }

    .red {
      color: darkred;
    }

    .bar {
      padding-top: 3px;
      width: calc(100% - 300px);

      .info {
        margin-bottom: 3px;
        font-size: 0.9rem;
        margin-top: 2px;
        color: darkred;
      }
    }
  }
}

.cont-btn {
  padding-top: 10px;
  border-top: 1px solid $border-color;
}
