.cont {
  display: flex;
  align-items: center;
  .title {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 300;
    padding-bottom: 5px;
    //border-bottom: 1px solid #cbcbcb;
    height: 60px;
    line-height: 60px;
    color: #29228b;
    flex-grow: 1;
  }
  .desc {
    text-align: right;
  }
}




@media(max-width: 998px) {
  .cont {
    .title {
      font-size: 1.2rem;
    }
  }

}
