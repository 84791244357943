$nav-height: 90px;

.space {
  flex-grow: 1;
}

.appbar {
  height: $nav-height;
  position: fixed;
  justify-content: space-between;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 20px 0 20px;
  z-index: 100;
  background: transparent;


  .btn {
    line-height: $nav-height;
    font-size: 1.2rem;
    font-weight: 100;
  }
}

.contents {
  min-height: 100vh;
}

.btn-color {
  color: #eeeeee !important;
  font-size: 1.2rem;
}
