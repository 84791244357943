@import "/src/styles/temp1/temp1";

.side-title {
  width: 100%;
}

.side-titleBar {
  padding: 0 !important;
}

.userHeader {
  width: 30%;
  border-right: 1px solid $border-color;
}

.userValue {
  width: 70%;
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding-left: 30px;
  color: $black-color;
}

.marginBottom {
  margin-bottom: 30px;
}
