@import "./colors.module";

.chip1 {
  background-color: $chip1-color !important;
  color: $white-color !important;
}

.chip2 {
  background-color: $chip2-color !important;
  color: $white-color !important;
}

.chip3 {
  background-color: $chip3-color !important;
  color: $white-color !important;
}

.chip4 {
  background-color: $chip4-color !important;
  color: $white-color !important;
}

.myPage-chip1-color {
  background-color: $myPage-chip1-color !important;
  color: $white-color !important;
}

.myPage-chip2-color {
  background-color: $myPage-chip2-color !important;
  color: $white-color !important;
}

.myPage-chip3-color {
  background-color: $myPage-chip3-color !important;
  color: $white-color !important;
}

.myPage-chip4-color {
  background-color: $myPage-chip4-color !important;
  color: $white-color !important;
}

.myPage-chip5-color {
  background-color: $myPage-chip5-color !important;
  color: $white-color !important;
}

.myPage-chip6-color {
  background-color: $myPage-chip6-color !important;
  color: $white-color !important;
}

.myPage-chip7-color {
  background-color: $myPage-chip7-color !important;
  color: $white-color !important;
}