@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import "../../../../styles/temp2/temp2";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 79px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid grey;
  z-index: 1;
}

.header__navBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1130px;
  margin: 0 auto;

  .navBar__container {
    display: flex;
    width: 100%;

    .navBar__logo {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: $point-color;
      font-family: 'Staatliches', cursive;
      font-size: 24px;
      font-weight: 400;
    }

    .navBar__rightMenu {
      width: 80%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.navBar-inactive {
  color: grey;
  margin-right: 20px;

  &:hover {
    color: black;
  }
}

.navBar-active {
  color: black;
  margin-right: 20px;

  &:hover {
    color: black
  }
}

.contents {
  min-height: calc(100vh - 79px);
  margin-top: 79px;
  background-color: #e3f2fd;
}
