@import "/src/styles/temp1/temp1";

.chart {
  display: block;
  margin: 0 auto;
}

.side-flexBox {
  width: 50%;
}

.padding {
  margin: 20px 0;
  padding: 0 20px;
}

.divide {
  width: 33.33%;
}

.rightDivide {
  border-right: 1px solid $border-color;

  &:last-child {
    border-right: none;
  }
}