@import "./colors.module";

.modalContainer {
  padding: 20px;

  .side-flexBox {
    box-shadow: none !important;
    border: none;
  }

  .borderBottom {
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 1px solid $border-color !important;
    }
  }

  .borderTop {
    border-top: 1px solid $border-color;
  }

  .alertTitleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $alert-color;
  }

  .titleBox-text {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .alertDescription {
    flex-direction: column !important;
    color: $text-color;
    min-height: 30px !important;
    margin-bottom: 30px;
    font-size: 1rem !important;
  }

  .clearBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $point-color;
  }

  .buttonBox {
    justify-content: flex-end !important;
  }
}