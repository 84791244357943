@import "/src/styles/temp1/colors.module";
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

@font-face {
  font-family: "Vitro Core";
  src: url("../../../../assets/fonts/vitro/vitro-core.otf");
}

@font-face {
  font-family: "Black Han Sans";
  src: url("../../../../assets/fonts/Black_Han_Sans/BlackHanSans-Regular.ttf") format("truetype");
}

.cont-section-1 {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background: url("../../../../assets/img/index/temp1/1.jpg") no-repeat center;

  .title {
    line-height: 100px;
    font-weight: 900;
    text-shadow: rgba(0, 0, 0, 0.5);
    font-size: 4.5rem;
    text-align: center;
    margin-bottom: 40px;
    color: #ffffff;
  }

  .sub-title {
    text-align: center;
    font-size: 2rem;
    color: #eaeaea;
  }
}

@media(max-width: 998px) {
  .cont-section-1 {
    .title {
      font-size: 1.2rem;
      line-height: 30px;
    }

    .sub-title {
      font-size: 0.9rem;
    }
  }
}

.fullContainer {
  width: 100%;
}

.sectionOne {
  height: 100vh;
  background-size: cover;
  background: url("../../../../assets/img/index/temp1/1.jpg") no-repeat center;
}

.sectionOne__container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  max-width: 1110px;


  .container__left {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white-color;
    text-shadow: 0 0 10px rgba(0, 0, 0, 1);


    .typingText {
      margin-top: 20px;
      font-size: 2rem;
    }

    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
    }

    h2 {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    h4 {
      margin-top: 50px;
    }
  }

  .container__right {
    width: 100%;
    position: relative;
    max-width: 50%;
  }
}

.sectionTwo__background {
  background-color: #edeeef;
}

.sectionTwo__container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1110px;
  padding: 60px 0;
  //color: $white-color;
  //text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);


  .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .sectionTwo__paper {
    border-top: 5px solid #2363a7;
    padding: 30px;
    text-shadow: none;

    .paper__iconBox {
      background-color: #dfe0eb;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #007aff;
      margin-bottom: 20px;
    }

    h5 {
      font-weight: 700;
      color: #2d3748;
      font-size: 18px;
      margin-bottom: 20px;
    }

    h6 {
      color: #718096;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

.sectionThird__background {
  background-size: cover;
  background: url("../../../../assets/img/index/temp1/2.jpg") no-repeat center;
  filter: grayscale(50%);
}

.sectionThird__container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 1110px;
  padding: 60px 0;

  .container__left {
    width: 60%;
    text-align: center;
    font-size: 55px;
    color: white;
  }

  .container__right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
  }
}

.sectionFourth__background {
  background-color: #ffffff;
}

.sectionFourth__container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1110px;
  padding: 60px 0;

  .sectionFourth-contentBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sectionFourth__paper {
    width: 50%;
    display: flex;
    justify-content: space-between;

    .paper__iconBox {
      width: 15%;

      .iconBox__box {
        margin-top: 9px;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        background-color: red;
        border-radius: 5px;
      }

      .iconBox__firstColor {
        background-color: #dc2f4b;
      }

      .iconBox__secondColor {
        background-color: #df8d00;
      }

      .iconBox__thirdColor {
        background-color: #197ed2;
      }

      .iconBox__fourthColor {
        background-color: #17a822;
      }
    }

    .paper__textBox {
      width: 90%;

      h5 {
        font-weight: 900;
        font-size: 28px;
        line-height: 1.5;
      }

      h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

.sectionFifth__background {
  background: url("../../../../assets/img/index/temp1/3.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
}

.sectionFifth__container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1110px;
  padding: 60px 0;

  .sectionFifth__textBox {
    font-size: 55px;
    font-weight: 900;
    text-align: center;
  }
}

.section__textBox {
  margin-bottom: 70px;

  .title {
    font-size: 55px;
    font-weight: 900;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .description {
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
  }
}


.inform {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;

  .box-title {
    background: url("../../../../assets/img/index/fog.png") no-repeat center;
    background-position-y: 70px;

    .title {
      height: 300px;
      line-height: 8rem;
      width: 100vw;
      font-size: 7em;
      font-weight: 500;
      margin: 0;
      color: #fff;
      text-align: center;
      background: #fbf8ec;
      background: linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}


.box-cloud {
  overflow: hidden;
  background: url("../../../../assets/img/index/clouds.png");
  animation: cloudLoop 80s linear infinite;
  position: absolute;
  width: 200vw;
  height: 50vh;
  z-index: 200;
  left: -20px;
  top: 50vh;
}


@keyframes cloudLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}


.box-info {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 30px;

  .title {
    color: #333;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
    font-weight: 100;
  }

  .description {
    color: #9a9a9a;
    font-weight: 100;
    line-height: 2rem;
    font-size: 1.2rem;
  }

  .box-description {
    svg {
      color: #51cbce;
      width: 50px;
      height: 50px;
    }

    .title {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .contents {
      color: #9a9a9a;
    }
  }

  .box-marketing {
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 5px;
    overflow: hidden;
    height: 300px;
    filter: grayscale(50%);
    background-blend-mode: darken;
    backdrop-filter: blur(10%) brightness(30%);
    object-fit: cover;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);

    text-align: center;

    .title {
      color: #ffffff;
      filter: none;
      font-weight: 200;
      font-size: 1.8rem;
      margin-bottom: 20px;
      margin-top: 40px;
    }

    .icon {
      margin-bottom: 40px;

      svg {
        width: 70px;
        height: 70px;
        color: #ffffff;
        font-size: 3rem;
        padding: 15px;
        border-radius: 35px;
        border: 3px solid rgba(255, 255, 255, 0.4);
      }
    }

    .contents {
      font-weight: 100;
      color: #eeeeee;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }
}

.box-left-icon {
  display: flex;
  text-align: left;
  margin-bottom: 40px;

  .icon {
    width: 50px;
    margin-right: 20px;

    svg {
      color: #51cbce;
      width: 40px;
      height: 40px;
    }
  }

  .contents {
    flex-grow: 1;

    .word {
      font-size: 1.2rem;
      margin-bottom: 5px;
      font-weight: 100;
    }

    .description {
      line-height: 1.4rem;
      font-size: 1rem;
    }
  }
}

@media (max-width: 998px) {

  h2 {
    font-size: 50px;
  }

  .fullContainer {
    flex-wrap: wrap;

    .sectionOne__background {
      background-size: 100%;
      background-position: 40% 90%;

      .sectionOne__container {

        .container__left {
          padding: 110px 20px;
          justify-content: flex-start;
          width: 100%;
          max-width: 100%;
        }

        .container__right {
          display: none;
        }
      }
    }

    .sectionThird__container {
      flex-wrap: wrap;

      .container__left {
        width: 100%;
      }

      .container__right {
        margin-top: 50px;
        width: 100%;
        height: 200px;
        padding-left: 30px;
      }
    }
  }

  .sectionFourth__container {
    .sectionFourth-contentBox {
      .sectionFourth__paper {
        width: 90%;
      }
    }
  }

  .sectionFifth__container {
    .sectionFifth__textBox {
      font-size: 30px;
    }
  }
}
