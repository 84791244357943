.cont {
  padding: 40px;
  background: white;
  border-radius: 10px;
  min-width: 370px;

  .box-form {
    height: 350px;
    p {
      margin-bottom: 5px;
    }

    input {
      margin-bottom: 20px;
    }

    .info {
      font-size: 0.75rem;
      margin-bottom: 10px;
      color: gray;
    }
  }
}


@media (max-width: 998px) {

}
