@import "../../../../../styles/table";

.banner {
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;

  a {
    width: 33%;

    img {
      width: 50%;
    }
  }


}
