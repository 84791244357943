@import "src/styles/colors";

.cont-tel-reporter {
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 0 20px 0 20px;
  background: #fffefa;
  height: 600px;


  .box-tel-reporter {
    display: flex;
    flex-direction: column-reverse;
    padding: 10px 0 10px 0;

    div {
      font-size: 0.9rem;
    }

    .msg {
      border: 1px solid $border-color;
      border-radius: 5px;
      padding: 5px;
      background: #f9fffd;
      margin-bottom: 20px;

      .msg-info {
        color: #7b7b7b;
        margin-bottom: 0;
      }

      .content {
        margin-top: 10px;
      }
    }

  }


}
